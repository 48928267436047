/* src/About.css */
.about {
  padding: 40px;
  text-align: center;
  /* font-family: 'Comic Sans MS', cursive, sans-serif; */
  border-radius: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
span {
  color: #c1ff00;
}
.about h2 {
  color: #c1ff00;
}

.about p {
  font-size: 1.1em;
  color: #ffffff;
}

.alien-musk-img {
  width: 250px;
  height: auto;
  margin-top: 20px;
  border-radius: 50%;
  filter: drop-shadow(0px 10px 10px #c1ff00);
}
