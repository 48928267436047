@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}

a {
  color: #84309c;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 5px;
  padding: 2px;
  cursor: url("../assets/cursor.png") 10 10, auto;
  background-color: #000000;
  overflow-x: hidden;
  color: #9bafc1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.buy {
  border: 3px solid grey;
  border-radius: 30px;
}